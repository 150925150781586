const PP = () => {
  return (
    <div className="prose prose-lg px-4 py-8 mx-auto max-w-7xl text-sm md:text-base">
      <h1 className="text-3xl font-bold">개인정보처리방침</h1>
      <h1 className="text-3xl font-bold">(Privacy Policy)</h1>
      <p className="mt-8">
        fitanything('이하 '서비스'라 함)에 오신 것을 환영합니다. 본 서비스는
        사용자의 개인정보를 중요시하며, '정보통신망 이용진 및 정보보호 등에 관한
        법률'을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 사용자의
        개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
        어떠한 조치가 취해지고 있는지 알려드립니다.
      </p>
      <p className="mt-8">
        1. 개인정보의 수집 및 이용 목적: 회사는 다음의 목적을 위하여 개인정보를
        수집하고 있습니다. 서비스 제공에 필요한 최소한의 개인정보만을
        수집합니다.
        <p className="mt-2 pl-4">
          - 연락처 정보. 예를 들어, 당사는 귀하의 이름, 사용자 이름, 이메일
          주소를 수집할 수 있습니다. 또한 당사는 귀하의 주소, 전화번호, 회사명
          등 기타 연락처 정보를 수집할 수도 있습니다. 또한 당사는 귀하가 당사
          사이트에 업로드한 사진을 수집할 수도 있습니다.
        </p>
        <p className="mt-2 pl-4">
          - 금융 정보. 유료 서비스의 경우 당사는 계좌번호, 주소, 전화번호,
          회사명 등 기타 연락처 정보를 포함하여 결제와 관련된 금융 정보도 수집할
          수 있습니다.
        </p>
        <p className="mt-2 pl-4">
          - 귀하가 제출하거나 게시하는 정보. 당사는 귀하가 당사 사이트의 공공
          장소에 게시한 정보나 사진을 수집합니다. 당사는 귀하가 당사에 연락할
          때에도 정보를 수집합니다.
        </p>
        <p className="mt-2 pl-4">
          - 이용정보. 당사는 귀하가 콘텐츠를 보거나 클릭하는 시기를 포함하여
          귀하가 당사 웹사이트 및 애플리케이션을 방문하는 시기와 관련된 데이터를
          수집합니다.
        </p>
        <p className="mt-2 pl-4">
          - 장치 및 위치 정보. 당사는 귀하의 IP 주소, 프록시 서버, 운영 체제, 웹
          브라우저 및 추가 기능, 장치 기능 및 이동통신사에 대한 정보를
          수집합니다.
        </p>
        <p className="mt-2 pl-4">
          - 기타 정보. 귀하가 당사 웹사이트와 애플리케이션을 사용하는 경우
          당사는 귀하가 사용 중인 브라우저에 대한 정보를 수집할 수 있습니다.
          우리는 귀하가 어떤 사이트에서 왔는지, 또는 귀하가 우리를 떠날 때 어떤
          사이트를 방문하는지 확인할 수 있습니다.
        </p>
      </p>
      <p className="mt-4">
        2. 개인정보의 보유 및 이용 기간: 본 서비스 품질을 향상시키기 위해 당사는
        귀하의 요청에 대한 메타데이터를 저장하고 분석할 수 있습니다. 이용자의
        개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 후에는 해당
        정보를 지체 없이 파기합니다. 단, 상법, 전자상거래 등에서의 소비자 보호에
        관한 법률 등 법령의 규정에 의하여 보존할 필요가 있는 경우는 법령에서
        정한 기간 동안 이용자의 정보를 보관합니다.
      </p>
      <p className="mt-4">
        3. 개인정보의 제3자 제공에 관한 사항: 본 서비스는 이용자의 개인정보를
        '개인정보의 수집 및 이용 목적'에서 고지한 범위 내에서 사용하며, 이용자의
        사전 동의 없이 동 범위를 초과하여 이용하거나 원칙적으로 이용자의
        개인정보를 외부에 제공하지 않습니다. 단, 법률에 특별한 규정이 있는
        경우는 예외로 합니다.
      </p>
    </div>
  );
};

export default PP;
