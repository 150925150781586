import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Navbar,
  Collapse,
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
} from "@material-tailwind/react";
import { User, AlignJustify, X, LogOut } from "lucide-react";
import { useUserStore } from "../Store";

function ProfileMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const userStore = useUserStore((state) => state);
  const closeMenu = () => setIsMenuOpen(false);
  const navigate = useNavigate();

  const menuOnclick = (e: any) => {
    let label =
      e.currentTarget.id !== ""
        ? e.currentTarget.id
        : e.currentTarget.innerHTML;
    switch (label) {
      case "로그아웃":
        userStore.setInfo({
          accessToken: "",
          credit: 0,
          email: "",
          memberId: 0,
          nickname: "",
          provider: "",
          refreshToken: "",
        });
        navigate("/");
        break;
      case "내 프로필":
        navigate("/profile");
        break;
      case "내 예약 목록":
        navigate("/reserve");
        break;
      default:
    }
  };

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button
          variant="text"
          className="flex items-center rounded-full py-0.5 pr-0.5 pl-0.5 lg:ml-auto"
        >
          <Avatar
            variant="circular"
            size="sm"
            className="border border-gray-900"
            src="/user.png"
          />
        </Button>
      </MenuHandler>
      <MenuList>
        <MenuItem disabled>
          <Typography as="span" variant="small" className="font-normal">
            내 크레딧 : {String(userStore.credit)}
          </Typography>
        </MenuItem>
        <hr className="my-3" />
        <MenuItem
          key="내 프로필"
          onClick={closeMenu}
          className="flex items-center gap-2 rounded hover:bg-gray-500/10 focus:bg-gray-500/10 active:bg-gray-500/10"
        >
          <User id="내 프로필" onClick={menuOnclick} />
          <Typography
            as="span"
            variant="small"
            className="font-normal"
            style={{ width: "100%" }}
            color="inherit"
            onClick={menuOnclick}
          >
            내 프로필
          </Typography>
        </MenuItem>

        <MenuItem
          key="로그아웃"
          onClick={closeMenu}
          className="flex flex-row items-center gap-2 rounded hover:bg-red-500/10 focus:bg-red-500/10 active:bg-red-500/10"
        >
          <LogOut className="text-red-500" onClick={menuOnclick} />
          <Typography
            as="span"
            variant="small"
            className="font-normal"
            style={{ width: "100%" }}
            color="red"
            onClick={menuOnclick}
          >
            로그아웃
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export function StickyNavbar() {
  const [openNav, setOpenNav] = useState(false);

  const [loginCheck, setLoginCheck] = useState(false);

  const codeStore = useUserStore((state) => state);
  useEffect(() => {
    if (codeStore.email) {
      setLoginCheck(true);
    } else {
      setLoginCheck(false);
    }
  }, [codeStore.email]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navListOnclick = () => setOpenNav(false);

  const navList = (
    <ul className="mt-6 mb-6 flex flex-col gap-3 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Link onClick={navListOnclick} to="/about">
        <span className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md font-medium">
          소개
        </span>
      </Link>
      <Link onClick={navListOnclick} to="/tools">
        <span className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md font-medium">
          도구
        </span>
      </Link>
      <Link onClick={navListOnclick} to="/showcase">
        <span className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md font-medium">
          쇼케이스
        </span>
      </Link>
      <Link onClick={navListOnclick} to="/price">
        <span className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md font-medium">
          가격
        </span>
      </Link>
      <Link onClick={navListOnclick} to="/contact">
        <span className="text-gray-500 hover:text-gray-800 px-3 py-2 rounded-md font-medium">
          문의하기
        </span>
      </Link>
    </ul>
  );

  return (
    <Navbar className="mx-auto sticky top-0 z-50 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4">
      <div className="flex items-center justify-between h-8">
        <Link to="/">
          <span className="font-fingerpaint text-xl text-gray-900">
            FIT ANYTHING
          </span>
        </Link>
        <div className="flex items-center gap-4">
          <div className="ml-10 hidden lg:block">{navList}</div>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center">
            {loginCheck ? <ProfileMenu /> : null}
            {!loginCheck ? (
              <Link to="/signin">
                <Button
                  color="gray"
                  variant="gradient"
                  className="hidden lg:inline-block"
                >
                  로그인
                </Button>
              </Link>
            ) : null}
          </div>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-gray-900 hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <X className="h-6 w-6" />
            ) : (
              <AlignJustify className="h-6 w-6" />
            )}
          </IconButton>
        </div>
      </div>
      <Collapse open={openNav}>
        {navList}
        {!loginCheck ? (
          <div className="flex items-center">
            <Link to="/signin" className="w-full">
              <Button fullWidth color="gray" variant="gradient">
                로그인
              </Button>
            </Link>
          </div>
        ) : null}
      </Collapse>
    </Navbar>
  );
}
