import { Button, Typography } from "@material-tailwind/react";
import { config } from "../config";

const Signin = () => {
  const kakaoLogin = () => {
    fetch(`${config.url}/api/auth/kakaoURL`)
      .then((response) => response.text())
      .then((data) => {
        window.location.href = data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const googleLogin = () => {
    fetch(`${config.url}/api/auth/googleURL`)
      .then((response) => response.text())
      .then((data) => {
        window.location.href = data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center m-4 mt-16 sm:m-8 md:m-16 gap-4 sm:gap-8 md:gap-16">
        <div className="flex flex-col items-center justify-center gap-20">
          <div className="flex flex-col items-center justify-center gap-4">
            <Typography className="font-extrabold text-3xl sm:text-4xl md:text-5xl p-2 bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block bg-clip-text text-transparent">
              로그인
            </Typography>

            <Typography
              color="gray"
              className="mt-1 font-normal text-sm md:text-base"
            >
              내 얼굴을 다양하게 표현 해보세요!
            </Typography>
          </div>
          {/* <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
            <div className="mb-1 flex flex-col gap-6">
              <Typography variant="h6" color="blue-gray" className="-mb-3">
                이메일
              </Typography>
              <Input
                crossOrigin={""}
                size="lg"
                placeholder="name@mail.com"
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
              <Typography variant="h6" color="blue-gray" className="-mb-3">
                비밀번호
              </Typography>
              <Input
                crossOrigin={""}
                type="password"
                size="lg"
                placeholder="********"
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
              />
            </div>
            <Button className="mt-6" fullWidth>
              로그인
            </Button>
            <Typography color="gray" className="mt-4 text-center font-normal">
              처음이신가요?{" "}
              <a href="/signup" className="font-medium text-gray-900">
                회원가입
              </a>
            </Typography>
            <hr className="mt-4 border-blue-gray-50" />
            <Typography color="gray" className="mt-4 text-center font-normal">
              또는 SNS로 시작하기
            </Typography>
          </form> */}
          <div className="flex flex-col items-center justify-center gap-4">
            <Button
              color="yellow"
              size="lg"
              className="ml-10 mr-10"
              fullWidth
              onClick={kakaoLogin}
            >
              카카오 로그인
            </Button>

            <Button color="white" size="lg" className="ml-10 mr-10" fullWidth onClick={googleLogin}>
              구글 로그인
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
