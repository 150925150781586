import {
  Button,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
  Alert,
} from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";

export function Contact() {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

  return (
    <>
      <div className="grid m-4 mt-16 sm:m-8 md:m-16 gap-4 sm:gap-8 md:gap-16">
        <div className="flex flex-col items-center justify-center gap-20">
          <Typography className="p-1 text-3xl sm:text-4xl md:text-5xl font-extrabold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block text-transparent bg-clip-text">
            문의하기
          </Typography>

          <div className="flex flex-col w-full rounded-lg gap-8">
            <div className="flex flex-col w-full gap-2">
              <Alert color="amber" className="text-sm sm:text-base font-medium">
                📩 토큰 구매를 원할 시 문의 주세요
              </Alert>
              <Alert color="amber" className="text-sm sm:text-base font-medium">
                🎉 SNS 홍보 이벤트 진행 중 이에요
              </Alert>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center w-full text-center rounded-lg gap-4">
              <Link
                to="mailto:common.jaemin@gmail.com"
                className="w-full shadow-md"
              >
                <Button
                  color="gray"
                  variant="gradient"
                  className="w-full text-sm sm:text-base"
                >
                  이메일로 문의하기
                </Button>
              </Link>
              <Link
                to="https://open.kakao.com/o/sMV1ADkg"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full shadow-md"
              >
                <Button
                  color="yellow"
                  variant="gradient"
                  className="w-full text-sm sm:text-base"
                >
                  카카오톡으로 문의하기
                </Button>
              </Link>
            </div>
          </div>

          <div className="w-full">
            <Accordion open={open === 0}>
              <AccordionHeader onClick={() => handleOpen(0)}>
                다른 기능도 출시 되는건가요?
              </AccordionHeader>
              <AccordionBody>
                네 현재 계속 업데이트 중 입니다. 여러분들이 문의 주시면 언제든
                업데이트 해드립니다.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 1}>
              <AccordionHeader onClick={() => handleOpen(1)}>
                결과물이 잘 안나오세요?
              </AccordionHeader>
              <AccordionBody>
                각 페이지에 주의사항을 적었습니다. 부족하다면 문의해주시면
                답변드리겠습니다.
              </AccordionBody>
            </Accordion>
            <Accordion open={open === 2}>
              <AccordionHeader onClick={() => handleOpen(2)}>
                업데이트는 어떻게 요청하나요?
              </AccordionHeader>
              <AccordionBody>
                네 현재 계속 업데이트 중 입니다. 여러분들이 문의 주시면 언제든
                업데이트 해드립니다.
              </AccordionBody>
            </Accordion>

            <Accordion open={open === 3}>
              <AccordionHeader onClick={() => handleOpen(3)}>
                기술 협업도 가능한가요?
              </AccordionHeader>
              <AccordionBody>이메일로 문의 주세요</AccordionBody>
            </Accordion>

            <Accordion open={open === 4}>
              <AccordionHeader onClick={() => handleOpen(4)}>
                API도 제공하나요?
              </AccordionHeader>
              <AccordionBody>
                조만간 업데이트가 필요하시면 말씀해주세요
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
