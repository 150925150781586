import { Heart } from "lucide-react";
import { SocialIcon } from "react-social-icons";

const Footer = () => {
  return (
    <footer className="border-t">
      <div className="flex-col mx-auto py-10 space-y-5 text-center flex justify-center items-center">
        <div className="flex space-x-2">
          <p className="font-fingerpaint text-black">FIT ANYTHING</p>
        </div>

        <div className="flex space-x-1">
          <p className="text-xs text-black">Made with</p>
          <Heart fill="red" strokeWidth={2} className="h-4 w-4" />
          <p className="text-xs text-black">in Seoul, Korea</p>
        </div>

        <div className="flex space-x-2">
          <SocialIcon
            network="x"
            url="https://www.twitter.com/fitanything_/"
            style={{ height: 30, width: 30 }}
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            network="instagram"
            url="https://www.instagram.com/fit.anything/"
            style={{ height: 30, width: 30 }}
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            network="youtube"
            url="https://www.youtube.com/@fit.anything"
            style={{ height: 30, width: 30 }}
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            network="tiktok"
            url="https://www.tiktok.com/@fitanything"
            style={{ height: 30, width: 30 }}
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>

        <div className="flex space-x-2">
          <a href="/tos" className="text-xs underline">
            Terms of Service
          </a>
          <a href="/pp" className="text-xs underline">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
