import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { Check } from "lucide-react";
import { Link } from "react-router-dom";
const Price = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center mt-16 m-4 sm:m-16 md:gap-32">
        <Card
          color="gray"
          variant="gradient"
          className="w-full max-w-[20rem] p-8 "
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
          >
            <Typography
              variant="small"
              color="white"
              className="font-semibold uppercase"
            >
              standard
            </Typography>
            <Typography
              color="white"
              className="mt-6 flex justify-center gap-1 text-5xl font-semibold"
            >
              10,000 <span className="mt-3 text-3xl">원</span>
            </Typography>
          </CardHeader>
          <CardBody className="p-0">
            <ul className="flex flex-col gap-4">
              <li className="flex items-center gap-4">
                <span className="rounded-full border border-white/20 bg-white/20 p-1">
                  <Check />
                </span>
                <Typography className="font-normal">
                  100개의 크레딧을 충전합니다.
                </Typography>
              </li>
              <li className="flex items-center gap-4">
                <span className="rounded-full border border-white/20 bg-white/20 p-1">
                  <Check />
                </span>
                <Typography className="font-normal">
                  최소한의 서버 비용 입니다.
                </Typography>
              </li>
              <li className="flex items-center gap-4">
                <span className="rounded-full border border-white/20 bg-white/20 p-1">
                  <Check />
                </span>
                <Typography className="font-normal">
                  원하는 기술은 문의주세요
                </Typography>
              </li>
            </ul>
          </CardBody>
          <CardFooter className="mt-12 p-0">
            <Button
              size="lg"
              color="white"
              className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
              ripple={false}
              fullWidth={true}
            >
              <Link to="/contact">구매하기</Link>
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default Price;
