import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  DialogBody,
  Button,
  DialogFooter,
  Dialog,
  DialogHeader,
  Select,
  Option,
  Spinner,
  Input,
} from "@material-tailwind/react";
import { BadgeAlert, Upload, X } from "lucide-react";
import { useUserStore } from "../../Store";
import { config } from "../../config";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CautionComponent,
  HeaderSectionComponent,
  ToolsImageItem,
} from "./ToolsDetailItem";

const ToolsDetailPage = () => {
  const [value, setValue] = React.useState({
    sex: null,
    identity: null,
    fashion: null,
    colorTone: null,
    style: null,
    prompt: null,
    negativePrompt: null,
    aspectRatiosSelection: null,
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen((cur) => !cur);
  const userStore = useUserStore((state) => state);
  const [imageOpen, setImageOpen] = React.useState(false);
  const [clickImageSrc, setClickImageSrc] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const [imageData, setImageData] = useState<{ imageLink: string }[]>([]);
  const [apiInfo, setApiInfo] = useState({
    apiUrl: "",
    inputImageCnt: "",
    searchOption: {
      sex: false,
      identity: false,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: null,
      negativePrompt: null,
      aspectRatiosSelection: null,
    },
    fileCheck:true
  });

  useEffect(() => {
    setApiInfo({
      apiUrl: state.apiUrl,
      inputImageCnt: state.inputImageCnt,
      searchOption: state.searchOption,
      fileCheck : state.fileCheck
    });
    setImageData(ToolsImageItem(state.type) || []);
  }, [state]);

  const handleChange = (e: any, type: string) => {
    let data;
    if (e?.currentTarget?.type) {
      //텍스트용
      data = e.currentTarget.value;
    } else {
      data = e;
    }

    switch (type) {
      case "sex":
        return setValue({
          ...value,
          sex: data,
        });
      case "identity":
        return setValue({
          ...value,
          identity: data,
        });
      case "fashion":
        return setValue({
          ...value,
          fashion: data,
        });
      case "colorTone":
        return setValue({
          ...value,
          colorTone: data,
        });
      case "style":
        return setValue({
          ...value,
          style: data,
        });
      case "prompt":
        return setValue({
          ...value,
          prompt: data,
        });
      case "negativePrompt":
        return setValue({
          ...value,
          negativePrompt: data,
        });
      case "aspectRatiosSelection":
        return setValue({
          ...value,
          aspectRatiosSelection: data,
        });
    }
  };

  const handleImageOpen = (e: any) => {
    if (!e) return;
    setClickImageSrc(e.target.src);
    setImageOpen((cur) => !cur);
  };

  const [files, setFiles] = useState<File[]>([]);
  const [excuteLoading, setExcuteLoading] = useState(false);
  const excuteClick = () => {
    const searchOptions = apiInfo.searchOption;
    const valueOptions = value as { [key: string]: any };
    const requiredOptions = Object.keys(searchOptions).filter(
      (option) => searchOptions[option as keyof typeof searchOptions]
    );

    if (apiInfo.searchOption.prompt && !value.prompt) {
      return alert("prompt 검색조건을 입력해주세요.");
    }

    for (let option of requiredOptions) {
      if (valueOptions[option] === null) {
        return alert("조건을 선택해주세요.");
      }
    }

    if(state.type === "mascotMaker"){
      valueOptions.prompt = `In the style of TOK, ${valueOptions.prompt}`;
    }

    if(state.type === "emojiMaker"){
      valueOptions.prompt = `A TOK emoji of ${valueOptions.prompt}`;
    }
    
    if(state.type === "appiconMaker"){
      valueOptions.prompt = `${valueOptions.prompt} app icon`;
    }
    
    if (!apiInfo.searchOption.prompt && files.length === 0)
      return alert("이미지를 등록해 주시기 바랍니다.");
    setExcuteLoading(true);
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file`, file, file.name);
    });
    formData.append("memberId", userStore.memberId.toString());
    requiredOptions.forEach((option) => {
      formData.append(option, valueOptions[option]);
    });
    
    fetch(`${config.url}${apiInfo.apiUrl}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${userStore.accessToken}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setExcuteLoading(false);
        if (result.status === 402) {
          return alert(result.message);
        }
        navigate("/profile");
        userStore.setInfo({
          credit: result.credit,
        });
      })
      .catch((error) => {
        alert("Error:" + error);
      });
  };
  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      for (let key in acceptedFiles) {
        if (!acceptedFiles[key].type.includes("image"))
          return alert("이미지만 업로드 하세요 (.PNG .JPG .JEPG)");
      }
      setFiles((prevFiles) => {
        console.log(apiInfo);
        const totalFiles = [...prevFiles, ...acceptedFiles].slice(
          0,
          Number(apiInfo.inputImageCnt)
        );
        return totalFiles;
      });
    },
    [apiInfo]
  );

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const {
    getRootProps,
    getInputProps,
  }: {
    getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
    getInputProps: () => JSX.IntrinsicElements["input"];
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <div className="flex flex-col m-4 mt-16 sm:m-8 md:m-16 gap-4 sm:gap-8 md:gap-16">
        <HeaderSectionComponent type={state.type}></HeaderSectionComponent>
        <section className="flex flex-col items-center justify-center w-full gap-4 p-5 bg-white rounded-lg shadow-lg">
          {apiInfo.searchOption.sex ? (
            <div className="relative w-full">
              <Select
                size="lg"
                label="성별을 선택하세요"
                onChange={(e) => handleChange(e, "sex")}
              >
                <Option value="man">남성 일러스트</Option>
                <Option value="woman">여성 일러스트</Option>
              </Select>
            </div>
          ) : null}
          {apiInfo.searchOption.identity ? (
            <div className="relative w-full">
              <Select
                size="lg"
                label="나이를 선택하세요"
                onChange={(e) => handleChange(e, "identity")}
              >
                <Option value="children">아이</Option>
                <Option value="teenagers">10대</Option>
                <Option value="twenties">20대</Option>
                <Option value="thirties">30대</Option>
                <Option value="forties">40대</Option>
                <Option value="fifties">50대</Option>
                <Option value="sixties">60대</Option>
                <Option value="seventies">70대</Option>
                <Option value="eighties">80대</Option>
              </Select>
            </div>
          ) : null}
          {apiInfo.searchOption.fashion ? (
            <div className="relative w-full">
              <Select
                size="lg"
                label="패션을 선택하세요"
                onChange={(e) => handleChange(e, "fashion")}
              >
                <Option value="casual fashion">캐주얼</Option>
                <Option value="formal fashion">정장</Option>
                <Option value="business casual fashion">비즈니스 캐주얼</Option>
                <Option value="streetwear fashion">스트리트웨어</Option>
                <Option value="sporty fashion">스포티</Option>
                <Option value="boho fashion">보호</Option>
                <Option value="grunge fashion">그런지</Option>
                <Option value="preppy fashion">프레피</Option>
                <Option value="punk fashion">펑크</Option>
                <Option value="goth fashion">고스</Option>
                <Option value="hip hop fashion">힙합</Option>
                <Option value="vintage fashion">빈티지</Option>
                <Option value="haute couture fashion">오트 쿠튀르</Option>
                <Option value="avant-garde fashion">아방가르드</Option>
                <Option value="minimalist fashion">미니멀리스트</Option>
                <Option value="luxury fashion">럭셔리</Option>
                {/*  */}
                <Option value="software developer uniform">개발자</Option>
                <Option value="architect uniform">건축가</Option>
                <Option value="mechanic uniform">정비사</Option>
                <Option value="engineer uniform">기술자</Option>
                <Option value="scientist uniform">과학자</Option>
                {/*  */}
                <Option value="police uniform">경찰</Option>
                <Option value="firefighter uniform">소방관</Option>
                <Option value="postal uniform">우체국</Option>
                <Option value="judge robe">판사</Option>
                <Option value="lawyer uniform">변호사</Option>
                {/*  */}
                <Option value="school uniform">학생</Option>
                <Option value="teacher uniform">선생님</Option>
                {/*  */}
                <Option value="doctor's uniform">의사</Option>
                <Option value="nurse uniform">간호사</Option>
                {/*  */}
                <Option value="military uniform">군복</Option>
                <Option value="navy uniform">해군</Option>
                <Option value="air force uniform">공군</Option>
                <Option value="marine uniform">해병대</Option>
                {/*  */}
                <Option value="sports uniform">스포츠</Option>
                <Option value="soccer uniform">축구</Option>
                <Option value="basketball uniform">농구</Option>
                <Option value="baseball uniform">야구</Option>
                <Option value="golf attire">골프</Option>
                <Option value="tennis attire">테니스</Option>
                {/*  */}
                <Option value="hairdresser uniform">미용사</Option>
                {/*  */}
                <Option value="insurance agent uniform">보험설계사</Option>
                {/*  */}
                <Option value="celebrity attire">연예인</Option>
                <Option value="artist attire">예술가</Option>
                <Option value="musician attire">음악가</Option>
                <Option value="actor costume">배우</Option>
                <Option value="dancer attire">댄서</Option>
                {/*  */}
                <Option value="farmer uniform">농부</Option>
                <Option value="gardener uniform">정원사</Option>
                {/*  */}
                <Option value="chef's uniform">요리사</Option>
                <Option value="barista uniform">바리스타</Option>
                <Option value="bartender uniform">바텐더</Option>
                <Option value="maid uniform">메이드</Option>
                {/*  */}
                <Option value="pilot uniform">조종사</Option>
                <Option value="flight attendant uniform">승무원</Option>
                <Option value="bus driver">버스기사</Option>
                <Option value="taxi driver">택시기사</Option>
                <Option value="security guard uniform">보안요원</Option>
                {/*  */}
                <Option value="journalist uniform">기자</Option>
                <Option value="photographer uniform">사진작가</Option>
                <Option value="writer uniform">작가</Option>
              </Select>
            </div>
          ) : null}
          {apiInfo.searchOption.colorTone ? (
            <div className="relative w-full">
              <Select
                size="lg"
                label="컬러톤을 선택하세요"
                onChange={(e) => handleChange(e, "colorTone")}
              >
                <Option value="simple color tone">기본</Option>
                <Option value="warm color tone">웜 톤</Option>
                <Option value="spring light color tone">웜(봄 라이트)</Option>
                <Option value="spring bright color tone">
                  웜(봄 브라이트)
                </Option>
                <Option value="autumn mute color tone">웜(가을 뮤트)</Option>
                <Option value="autumn deep color tone">웜(가을 딥)</Option>
                <Option value="cool color tone">쿨 톤</Option>
                <Option value="summer light color tone">쿨(여름 라이트)</Option>
                <Option value="summer mute color tone">쿨(여름 뮤트)</Option>
                <Option value="winter bright color tone">
                  쿨(겨울 브라이트)
                </Option>
                <Option value="winter deep color tone">쿨(겨울 딥)</Option>
                <Option value="vivid color tone">비비드(선명한)</Option>
                <Option value="strong color tone">스트롱(강한)</Option>
                <Option value="bright color tone">브라이트(밝은)</Option>
                <Option value="light color tone">라이트(연한)</Option>
                <Option value="pale color tone">페일(옅은)</Option>
                <Option value="dull color tone">덜(부드러운)</Option>
                <Option value="light grayish color tone">
                  라이트 그레이시(밝은 회색빛)
                </Option>
                <Option value="grayish color tone">그레이시(회색빛)</Option>
                <Option value="deep color tone">딥(진한)</Option>
                <Option value="dark color tone">다크(어두운)</Option>
                <Option value="dark grayish color tone">
                  다크 그레이시(어두운 회색빛)
                </Option>
              </Select>
            </div>
          ) : null}
          {apiInfo.searchOption.style ? (
            <div className="relative w-full">
              <Select
                size="lg"
                label="스타일을 선택하세요"
                onChange={(e) => handleChange(e, "style")}
              >
                <Option value="man">남성 일러스트</Option>
                <Option value="woman">여성 일러스트</Option>
              </Select>
            </div>
          ) : null}
          {apiInfo.searchOption.prompt ? (
            <div className="relative w-full">
              <Typography
                variant="small"
                color="red"
                className="mb-4 flex items-center gap-1 font-normal"
              >
                <BadgeAlert />
                영어로 작성하세요
              </Typography>
              <Input
                crossOrigin={undefined}
                label="프롬프트"
                onChange={(e) => handleChange(e, "prompt")}
              ></Input>
            </div>
          ) : null}

          {apiInfo.searchOption.negativePrompt ? (
            <div className="relative w-full">
              <Input
                crossOrigin={undefined}
                label="부정 프롬프트"
                onChange={(e) => handleChange(e, "negativePrompt")}
              ></Input>
            </div>
          ) : null}

          {apiInfo.searchOption.aspectRatiosSelection ? (
            <div className="relative w-full">
              <Select
                size="lg"
                label="사이즈를 선택하세요"
                onChange={(e) => handleChange(e, "aspectRatiosSelection")}
              >
                <Option value="704*1408">704 x 1408</Option>
                <Option value="704*1344">704 x 1344</Option>
                <Option value="768*1344">768 x 1344</Option>
                <Option value="768*1280">768 x 1280</Option>
                <Option value="832*1216">832 x 1216</Option>
                <Option value="832*1152">832 x 1152</Option>
                <Option value="896*1152">896 x 1152</Option>
                <Option value="896*1088">896 x 1088</Option>
                <Option value="960*1088">960 x 1088</Option>
                <Option value="960*1024">960 x 1024</Option>
                <Option value="1024*1024">1024 x 1024</Option>
                <Option value="1024*960">1024 x 960</Option>
                <Option value="1088*960">1088 x 960</Option>
                <Option value="1088*896">1088 x 896</Option>
                <Option value="1152*896">1152 x 896</Option>
                <Option value="1152*832">1152 x 832</Option>
                <Option value="1216*832">1216 x 832</Option>
                <Option value="1280*768">1280 x 768</Option>
                <Option value="1344*768">1344 x 768</Option>
                <Option value="1344*704">1344 x 704</Option>
                <Option value="1408*704">1408 x 704</Option>
                <Option value="1472*704">1472 x 704</Option>
                <Option value="1536*640">1536 x 640</Option>
                <Option value="1600*640">1600 x 640</Option>
                <Option value="1664*576">1664 x 576</Option>
                <Option value="1728*576">1728 x 576</Option>
              </Select>
            </div>
          ) : null}
          {apiInfo.fileCheck ? 
            <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors">
              <div
                className="flex flex-col items-center justify-center gap-4"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Upload className="h-16 w-16 text-gray-600" />
                <div className="text-center">
                  <Typography className="text-sm text-gray-700">
                    이미지를 업로드 하세요.
                  </Typography>
                  <Typography className="text-xs text-gray-500">
                    PNG, JPG, JPEG (MAX. 1024x1024px)
                  </Typography>
                </div>
              </div>
            </label> : null
          }
          <div className="grid grid-cols-3 gap-2">
            {files.map((file, index) => (
              <div key={index} className="relative">
                <Button
                  variant="gradient"
                  color="red"
                  className="p-1"
                  onClick={() => removeFile(index)}
                >
                  <X className="h-6 w-6" />
                </Button>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`${index + 1}`}
                  className="w-full h-auto rounded-lg"
                />
              </div>
            ))}
          </div>
          <div>
            <Button onClick={handleOpen} variant="gradient" color="black">
              실행하기
            </Button>
            <Dialog open={open} handler={handleOpen}>
              <DialogHeader>중요한 사용자 안내</DialogHeader>
              <DialogBody>
                <ul className="list-disc pl-5 mt-4 text-sm sm:text-base">
                  <li className="mt-2">
                    <strong>크레딧 차감</strong>: 해당 기술을 성공적으로
                    사용하면, 귀하의 계정에서 자동으로 크레딧이 차감됩니다.
                    크레딧은 이 기술의 사용료로 적용되며, 한 번 차감된 크레딧은
                    환불이 불가능합니다.
                  </li>
                  <li className="mt-2">
                    <strong>크레딧 확인</strong>: 기술을 실행하기 전에, 계정의
                    현재 크레딧 잔액을 확인해주시기 바랍니다. 크레딧이 부족할
                    경우, 기술 실행이 제한될 수 있습니다.
                  </li>
                  <li className="mt-2">
                    <strong>사용 조건</strong>: 모든 기술 사용은 당사의 이용
                    약관 및 개인정보 보호정책에 따릅니다. 사용 전 해당 문서들을
                    검토하시어 귀하의 권리와 의무를 숙지하시기 바랍니다.
                  </li>
                  <li className="mt-2">
                    <strong>기술 실행 전 확인</strong>: 실행하려는 기술의 세부
                    사항 및 작동 방식을 충분히 이해하였는지 확인해주시기
                    바랍니다. 불확실한 점이 있으시면, 실행 전에 당사의 고객
                    지원팀에 문의하여 도움을 받으시는 것이 좋습니다.
                  </li>
                  <li className="mt-2">
                    <strong>결과 예측</strong>: 해당 기술의 결과는 사용 환경,
                    입력 데이터 등 여러 요소에 의해 달라질 수 있습니다.
                  </li>
                </ul>
              </DialogBody>
              <DialogFooter>
                {excuteLoading ? (
                  <Spinner color="blue" />
                ) : (
                  <>
                    <Button
                      variant="text"
                      color="red"
                      onClick={handleOpen}
                      className="mr-1"
                    >
                      <span>취소하기</span>
                    </Button>
                    <Button
                      variant="gradient"
                      color="gray"
                      onClick={excuteClick}
                    >
                      <span>실행하기</span>
                    </Button>
                  </>
                )}
              </DialogFooter>
            </Dialog>
          </div>
        </section>
        <CautionComponent type={state.type}></CautionComponent>
        <section className="flex flex-col items-center justify-center p-10 bg-white rounded-lg shadow-lg">
          <Typography
            variant="h2"
            className="text-3xl md:text-4xl font-extrabold bg-gradient-to-r from-green-500 via-green-600 to-green-700 inline-block text-transparent bg-clip-text mb-8"
          >
            쇼케이스
          </Typography>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2">
            {imageData.map(({ imageLink }, index) => (
              <Card
                key={index}
                onClick={handleImageOpen}
                className="w-full cursor-pointer overflow-hidden transition-transform duration-300 ease-in-out transform hover:scale-105"
              >
                <img
                  className="h-full w-full object-cover object-center rounded-lg shadow-md hover:shadow-lg"
                  src={imageLink}
                  alt={`gallery-${index}`}
                />
              </Card>
            ))}
          </div>
          <Dialog size="sm" open={imageOpen} handler={handleImageOpen}>
            <DialogHeader className="justify-end">
              <Button
                variant="text"
                size="sm"
                onClick={handleImageOpen}
                className="p-0.5"
              >
                <X className="h-6 w-6" />
              </Button>
            </DialogHeader>
            <DialogBody className="relative">
              <img
                alt="nature"
                className="h-full w-full rounded-lg object-cover object-center"
                src={clickImageSrc}
              />
            </DialogBody>
            {/* <DialogFooter className="flex flex-row justify-start gap-2">
              <Typography className="font-normal">입력:</Typography>
              <div className="grid grid-cols-5 gap-2">
                <img
                  alt="small nature"
                  className="rounded-lg object-cover object-center"
                  src="../case/avatar-maker/s1.png"
                />
                <img
                  alt="small nature"
                  className="rounded-lg object-cover object-center"
                  src="../case/avatar-maker/s1.png"
                />
                <img
                  alt="small nature"
                  className="rounded-lg object-cover object-center"
                  src="../case/avatar-maker/s1.png"
                />
              </div>
            </DialogFooter> */}
          </Dialog>
        </section>
      </div>
    </>
  );
};

export default ToolsDetailPage;
