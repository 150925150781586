import { InstagramEmbed } from "react-social-media-embed";

const Showcase = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-4 m-4 lg:gap-8 lg:m-8">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          <div style={{ display: "grid", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/p/C74FZ2xpX_j/"
              width={325}
            />
          </div>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/p/C74ICkLMCcP/"
              width={325}
            />
          </div>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/p/C72wYPBJvtk/"
              width={325}
            />
          </div>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/p/C72zGKEsQWB/"
              width={325}
            />
          </div>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/p/C71fkUwJY2P/"
              width={325}
            />
          </div>
          <div style={{ display: "grid", justifyContent: "center" }}>
            <InstagramEmbed
              url="https://www.instagram.com/p/C72R8ETh5gq/"
              width={325}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Showcase;
