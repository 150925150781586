import React from "react";

const TOS = () => {
  return (
    <div className="prose prose-lg px-4 py-8 mx-auto max-w-7xl text-sm md:text-base">
      <h1 className="text-3xl font-bold">이용약관</h1>
      <h1 className="text-3xl font-bold">(Terms of Service)</h1>
      <p className="mt-8">
        fitanything('이하 '서비스'라 함)에 오신 것을 환영합니다. 본 서비스는
        사용자가 자신이 원하는 이미지에 대한 정보(이미지, 텍스트 등)를 입력하면
        이미지를 생성하고 이미지를 제공하는 서비스입니다. 또한, 토큰을 충전할 때
        결제가 이루어지는 서비스입니다. 본 약관은 사용자가 서비스를 이용함에
        있어 사용자간의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.
        귀하가 약관을 이해하지 못하거나 약관의 일부를 수락하지 않는 경우
        서비스를 이용해서는 안 됩니다. 서비스를 사용함으로써 귀하는 본 약관에
        동의함으로써 귀하의 법적 관할권에서 구속력 있는 계약을 체결할 수 있는
        법적 연령에 이르렀음을 나타냅니다. 귀하가 기업 또는 기타 법인을 대신하여
        서비스를 사용하는 경우 귀하는 해당 법인을 본 약관에 구속할 권한이 있음을
        진술합니다. 귀하가 법적 연령이 아니거나, 본 약관에 동의하지 않거나,
        귀하를 대신하여 서비스를 사용하게 될 법인을 구속할 수 없는 경우, 귀하는
        서비스에 액세스하거나 사용해서는 안 됩니다. 그러한 조건에서 서비스를
        사용하는 것은 완전히 승인되지 않으며 본 약관을 위반하는 것입니다.
      </p>
      <p className="mt-8">
        본 서비스를 이용함으로써 귀하는 다음 조건과 규칙을 모두 준수하는 데
        동의합니다.
      </p>
      <p className="mt-4">1. 본 서비스 이용에 따른 위험은 귀하의 책임입니다.</p>
      <p className="mt-4">
        2. 본 서비스를 이용하기 위해 서버로 전송된 모든 데이터에 대한 모든
        책임은 귀하에게 있습니다.
      </p>
      <p className="mt-4">
        3. 귀하는 서비스를 이용하여 불법 자료를 업로드하지 않을 것에 동의합니다.
      </p>
      <p className="mt-4">
        4. 귀하는 서비스를 귀하의 애플리케이션나 제 3자 애플리케이션에 통합하지
        않는다는 데 동의합니다.
      </p>
      <p className="mt-4">
        5. 귀하는 개인적 또는 상업적 목적으로 서비스를 사용할 수 있습니다.
      </p>
      <p className="mt-4">
        6. 당사는 언제든지 서비스를 변경하거나 중단할 권리를 보유합니다.
      </p>
      <p className="mt-4">
        7. 당사는 사전 통지 없이 본 계약 조건을 변경할 권리를 보유합니다.
      </p>
      <p className="mt-4">8. 서비스는 어떠한 보증도 제공하지 않습니다.</p>
    </div>
  );
};

export default TOS;
