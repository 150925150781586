import { create } from "zustand";
import { persist } from 'zustand/middleware'

const StorageKey = 'storage-key';
interface UserState {
    accessToken: string;
    credit: Number;
    email: string;
    memberId : Number;
    nickname : string;
    provider : string;
    refreshToken : string;
    setInfo: (state: Partial<UserState>) => void;
}

export const useUserStore = create<UserState>()(
    persist(
        (set) => ({
                accessToken: "",
                credit: 0,
                email: "",
                memberId : 0,
                nickname : "",
                provider : "",
                refreshToken : "",
                setInfo: (newState: Partial<UserState>) => set((state) => ({ ...state, ...newState })),
            }),
        {
            name: StorageKey
        }
    ),
)