import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import { SocialIcon } from "react-social-icons";

const About = () => {
  return (
    <div className="grid m-4 mt-16 sm:m-8 md:m-16 gap-4 sm:gap-8 md:gap-16">
      <div className="flex flex-col items-center justify-center gap-20">
        <Typography className="p-1 text-3xl sm:text-4xl md:text-5xl font-extrabold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block text-transparent bg-clip-text">
          우리의 서비스
        </Typography>
        <Typography className="p-2 text-base sm:text-lg md:text-xl">
          우리의 최첨단 인공지능 이미지 생성 기술을 활용하여,
          <span className="bg-yellow-100 text-red-800 font-semibold rounded px-2">
            당신만의 독특한 개성이 담긴 프로필 사진
          </span>{" "}
          을 만들어 드립니다. 또한, 다양한 스타일의 변환을 통해 아바타, 캐릭터
          사진 등 당신이 원하는 어떤 이미지도 구현해낼 수 있습니다.
          <span className="bg-yellow-100 text-red-800 font-semibold rounded px-2">
            여러분의 요청에 맞춰
          </span>{" "}
          개성있고 독특한 결과물을 지속적으로 제공해드리는 것이 우리의
          목표입니다.
        </Typography>

        <div className="flex flex-col items-center justify-center gap-4 m-4 lg:gap-8 lg:m-8">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            <div className="grid gap-4">
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s12.png"
                  alt="gallery-12"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src="../showcase/s13.png"
                  alt="gallery-13"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s14.png"
                  alt="gallery-14"
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s15.png"
                  alt="gallery-15"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s16.png"
                  alt="gallery-16"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s17.png"
                  alt="gallery-17"
                />
              </div>
            </div>

            <div className="grid gap-4">
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s18.png"
                  alt="gallery-18"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src="../showcase/s19.png"
                  alt="gallery-19"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src="../showcase/s20.png"
                  alt="gallery-20"
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s21.png"
                  alt="gallery-21"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s22.png"
                  alt="gallery-22"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s23.png"
                  alt="gallery-23"
                />
              </div>
            </div>
          </div>
        </div>

        <Typography className="p-1 text-3xl sm:text-4xl md:text-5xl font-extrabold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block text-transparent bg-clip-text">
          우리의 비전
        </Typography>
        <Typography className="p-2 text-base sm:text-lg md:text-xl">
          우리의 비전은 단순한 이미지 생성을 넘어,
          <span className="bg-yellow-100 text-red-800 font-semibold rounded px-2">
            개인의 개성과 창의성을 무한히 표현할 수 있는 플랫폼
          </span>{" "}
          을 만드는 것입니다. 인공지능 기술의 발전과 함께, 더 다양하고, 더
          정교하며, 더 창의적인 이미지를 생성할 수 있게 될 것입니다. 이를 통해
          사용자는 자신만의 맞춤형 이미지로 소셜 미디어 및 다양한 디지털
          플랫폼에서 개성을 표현하고 돋보일 수 있습니다.
          <span className="bg-yellow-100 text-red-800 font-semibold rounded px-2">
            우리의 최종 목표는 기술의 한계를 넘어, 사용자의 상상을 현실로 만드는
            것
          </span>{" "}
          입니다.
        </Typography>

        <div className="flex flex-col items-center justify-center gap-4 m-4 lg:gap-8 lg:m-8">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            <div className="grid gap-4">
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s0.png"
                  alt="gallery-0"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src="../showcase/s1.png"
                  alt="gallery-1"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s2.png"
                  alt="gallery-2"
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s3.png"
                  alt="gallery-3"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s4.png"
                  alt="gallery-4"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src="../showcase/s5.png"
                  alt="gallery-5"
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s6.png"
                  alt="gallery-6"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center "
                  src="../showcase/s7.png"
                  alt="gallery-7"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s8.png"
                  alt="gallery-8"
                />
              </div>
            </div>
            <div className="grid gap-4">
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s9.png"
                  alt="gallery-9"
                />
              </div>
              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s10.png"
                  alt="gallery-10"
                />
              </div>

              <div>
                <img
                  className="h-auto max-w-full rounded-lg object-cover object-center"
                  src="../showcase/s11.png"
                  alt="gallery-11"
                />
              </div>
            </div>
          </div>
        </div>

        <Typography className="p-1 text-3xl sm:text-4xl md:text-5xl font-extrabold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block text-transparent bg-clip-text">
          개발자들
        </Typography>
        <div className="flex md:flex-row flex-col gap-2 items-center justify-center">
          <Card className="md:w-1/3 w-3/4">
            <CardHeader floated={false} className="h-full">
              <img src="./home/s23.png" alt="profile-0" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography
                color="blue-gray"
                className="mb-2 font-semibold lg:text-xl md:text-lg text-base"
              >
                Jaemin Jeong
              </Typography>
              <Typography
                color="blue-gray"
                className="font-medium md:text-base text-sm"
                textGradient
              >
                AI developer
              </Typography>
            </CardBody>
            <CardFooter className="flex justify-center gap-7 pt-2">
              <SocialIcon
                network="instagram"
                url="https://www.instagram.com/jjxxmiin/"
                style={{ height: 30, width: 30 }}
              />
              <SocialIcon
                network="linkedin"
                url="https://www.linkedin.com/in/jaemin-jeong-39833619a/"
                style={{ height: 30, width: 30 }}
              />
              <SocialIcon
                network="github"
                url="https://github.com/jjxxmiin"
                style={{ height: 30, width: 30 }}
              />
            </CardFooter>
          </Card>
          <Card className="md:w-1/3 w-3/4">
            <CardHeader floated={false} className="h-full">
              <img src="./home/s1.png" alt="profile-1" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography
                color="blue-gray"
                className="mb-2 font-semibold lg:text-xl md:text-lg text-base"
              >
                Wonyoung Cheong
              </Typography>
              <Typography
                color="blue-gray"
                className="font-medium md:text-base text-sm"
                textGradient
              >
                Backend developer
              </Typography>
            </CardBody>
            <CardFooter className="flex justify-center gap-7 pt-2">
              <SocialIcon
                network="github"
                url="https://github.com/cheongwonyoung/"
                style={{ height: 30, width: 30 }}
              />
            </CardFooter>
          </Card>
        </div>
        <div className="flex md:flex-row flex-col gap-2 items-center justify-center">
          <Card className="md:w-1/3 w-3/4">
            <CardHeader floated={false} className="h-full">
              <img src="./home/s22.png" alt="profile-1" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography
                color="blue-gray"
                className="mb-2 font-semibold lg:text-xl md:text-lg text-base"
              >
                Jeongsik Oh
              </Typography>
              <Typography
                color="blue-gray"
                className="font-medium md:text-base text-sm"
                textGradient
              >
                Frontend developer
              </Typography>
            </CardBody>
            <CardFooter className="flex justify-center gap-7 pt-2">
              <SocialIcon
                network="google"
                url="mailto:dumoondong@gmail.com"
                style={{ height: 30, width: 30 }}
              />
            </CardFooter>
          </Card>
          <Card className="md:w-1/3 w-3/4">
            <CardHeader floated={false} className="h-full">
              <img src="./home/s24.png" alt="profile-1" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography
                color="blue-gray"
                className="mb-2 font-semibold lg:text-xl md:text-lg text-base"
              >
                Hyunghoon Kim
              </Typography>
              <Typography
                color="blue-gray"
                className="font-medium md:text-base text-sm"
                textGradient
              >
                Researcher
              </Typography>
            </CardBody>
            <CardFooter className="flex justify-center gap-7 pt-2">
              <SocialIcon
                network="github"
                url="https://github.com/K-HyungHoon/"
                style={{ height: 30, width: 30 }}
              />
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default About;
