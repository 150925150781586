import {
  Button,
  Typography,
  Input,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
  Card,
  Chip,
  Spinner,
} from "@material-tailwind/react";
import { useUserStore } from "../Store";
import { config } from "../config";
import {
  AlarmClock,
  Download,
  Image,
  LayoutDashboard,
  User,
  RefreshCcw,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import JSZip from "jszip";

type SpinnerVisibleState = {
  [purchaseId: string]: boolean;
};

const TABLE_HEAD = ["도구", "상태", "실행날짜", "사용크레딧"];

const findIdByValue = (value: undefined): string | undefined => {
  let toolsName = [
    { text: "나만의 일러스트 캐릭터", value: "avatarMaker" },
    { text: "나만의 다양한 캐릭터", value: "variantAvatarMaker" },
    { text: "나만의 레고 캐릭터", value: "legoAvatarMaker" },
    { text: "나만의 마크 캐릭터", value: "minecraftAvatarMaker" },
    { text: "나만의 픽셀 캐릭터", value: "pixelAvatarMaker" },
    { text: "나만의 피규어 캐릭터", value: "figureAvatarMaker" },
    { text: "나만의 유니버스 캐릭터", value: "universeMaker" },
    { text: "애니메이션 필터", value: "imageToAnime" },
    { text: "나만의 앱 아이콘", value: "appiconMaker" },
    { text: "나만의 이모티콘", value: "emojiMaker" },
    { text: "나만의 마스코트", value: "mascotMaker" },
    { text: "일반 이미지 생성하기", value: "generalImageMaker" },
    { text: "실사 이미지 생성하기", value: "realisticImageMaker" },
    { text: "애니 이미지 생성하기", value: "animeImageMaker" },
  ];
  const item = toolsName.find((item) => item.value === value);
  return item ? item.text : undefined;
};

export default function Profile() {
  useEffect(() => {
    reloadFunc();
  }, []);

  const reloadFunc = () => {
    fetch(
      `${config.url}/api/member/completedList?memberId=${userStore.memberId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userStore.accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 401) {
          alert("다시 로그인해주세요.");
          userStore.setInfo({
            accessToken: "",
            credit: 0,
            email: "",
            memberId: 0,
            nickname: "",
            provider: "",
            refreshToken: "",
          });
          return navigate("/signin");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(
      `${config.url}/api/member/completedList?memberId=${userStore.memberId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userStore.accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        let dataList = result.completedList;
        let list: any[] = [];
        let AllData: any[] = [];
        const gubunData: { [key: string]: string } = {
          processing: "진행중",
          succeeded: "완료",
          failed: "실패",
        };
        for (let key in dataList) {
          if (dataList[key].state === "succeeded") {
            list.push(dataList[key]);
          }
          AllData.push({
            name: dataList[key].versionName,
            job: gubunData[dataList[key].state],
            date: dataList[key].createdAt,
            credit: dataList[key].price,
          });
        }
        setAllList(AllData);
        setCompletedList(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const userStore = useUserStore((state) => state);
  const [AllList, setAllList] = useState([
    {
      name: undefined,
      job: undefined,
      date: undefined,
      credit: undefined,
    },
  ]);
  const [completedList, setCompletedList] = useState([
    {
      completed: false,
      createdAt: undefined,
      outputList: [],
      price: undefined,
      purchaseId: undefined,
      versionName: undefined,
    },
  ]);
  const [imageIndexes, setImageIndexes] = useState<{ [key: string]: number }>(
    {}
  );

  const downloadSingleImage = async (url: string, filename: string) => {
    const response = await fetch(url, {
      mode: "cors",
      cache: "no-cache",
    });
    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
    setExcuteLoading(false);
  };
  const [excuteLoading, setExcuteLoading] = useState(false);
  const downloadImagesAsZip = async (outputList: any) => {
    const zip = new JSZip();
    for (const [index, imageUrl] of outputList.entries()) {
      const response = await fetch(imageUrl, {
        mode: "cors",
        cache: "no-cache",
      });
      const blob = await response.blob();
      zip.file(`image${index + 1}.png`, blob);
    }
    const content = await zip.generateAsync({ type: "blob" });
    const downloadUrl = URL.createObjectURL(content);

    const newWindow = window.open(downloadUrl, "_blank");
    if (newWindow) {
      newWindow.focus();
      setExcuteLoading(false);
    } else {
      alert("팝업을 허용해주세요 (인앱브라우저는 동작하지 않아요)");
      setExcuteLoading(false);
    }
  };

  const handleDownload = (result: any) => {
    setExcuteLoading(true);
    let outputList = result.outputList;
    if (outputList.length === 1) {
      downloadSingleImage(outputList[0], "다운로드");
    } else if (outputList.length > 1) {
      downloadImagesAsZip(outputList);
    }
  };

  const [spinnerVisible, setSpinnerVisible] = useState<SpinnerVisibleState>({});
  const handleNextImage = (purchaseId: string) => {
    setSpinnerVisible((prev) => ({ ...prev, [purchaseId]: true }));
    return setTimeout(() => {
      setSpinnerVisible((prev) => ({ ...prev, [purchaseId]: false }));
      setImageIndexes((prevIndexes) => ({
        ...prevIndexes,
        [purchaseId]: (prevIndexes[purchaseId] || 0) + 1,
      }));
    }, 500);
  };

  const handlePrevImage = (purchaseId: string) => {
    setSpinnerVisible((prev) => ({ ...prev, [purchaseId]: true }));
    return setTimeout(() => {
      setSpinnerVisible((prev) => ({ ...prev, [purchaseId]: false }));
      setImageIndexes((prevIndexes) => ({
        ...prevIndexes,
        [purchaseId]: Math.max(0, (prevIndexes[purchaseId] || 0) - 1),
      }));
    }, 500);
  };

  const navigate = useNavigate();
  const chargeClick = () => {
    fetch(
      `${config.url}/api/member/coins/charge?memberId=${userStore.memberId}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userStore.accessToken}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        return response.json();
      })
      .then((result) => {
        if (result.status === 401) {
          alert("토큰이 만료되었습니다 다시 로그인해주세요.");
          userStore.setInfo({
            accessToken: "",
            credit: 0,
            email: "",
            memberId: 0,
            nickname: "",
            provider: "",
            refreshToken: "",
          });
          return navigate("/signin");
        }
        // debugger;
        alert("충전완료");
        userStore.setInfo({ credit: result.credit });
      })
      .catch((error) => {
        alert("Error:" + error);
      });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center m-4 mt-16 sm:m-8 md:m-16 gap-4 sm:gap-8 md:gap-16">
        <div className="flex flex-col items-center justify-center gap-20 w-full">
          <Typography className="text-3xl sm:text-4xl md:text-5xl font-extrabold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block text-transparent bg-clip-text">
            내 프로필
          </Typography>
          {/* <Button
            color="black"
            variant="outlined"
            size="sm"
            onClick={reloadFunc}
          >
            <RefreshCcw />
          </Button> */}
          <div>
            <Typography
              color="red"
              variant="paragraph"
              className="font-bold text-red-500"
            >
              충분히 기다리신 뒤 "F5"를 눌러주세요.
            </Typography>
          </div>
          <div className="flex flex-col gap-8 w-full">
            <Tabs value="dashboard">
              <TabsHeader className="bg-deep-purple-50">
                <Tab key="Dashboard" value="dashboard">
                  <div className="flex items-center gap-2 font-semibold">
                    <LayoutDashboard className="h-6 w-6" />
                  </div>
                </Tab>
                <Tab key="Profile" value="profile">
                  <div className="flex items-center gap-2 font-semibold">
                    <User className="h-6 w-6" />
                  </div>
                </Tab>
                <Tab key="Storage" value="storage">
                  <div className="flex items-center gap-2 font-semibold">
                    <Image className="h-6 w-6" />
                  </div>
                </Tab>
              </TabsHeader>
              <TabsBody>
                <TabPanel key="Dashboard" value="dashboard">
                  <Card className="w-full overflow-auto h-[50vh]">
                    <table className="w-full min-w-max table-auto text-left">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head, index) => (
                            <th
                              className="border-b  bg-deep-purple-50 p-4"
                              key={index}
                            >
                              <Typography
                                variant="small"
                                color="black"
                                className="font-normal leading-none opacity-70"
                              >
                                {head || "Default Value"}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {AllList[0]?.name !== undefined &&
                          AllList.map(({ name, job, date, credit }, index) => (
                            <tr key={index}>
                              <td className="p-4">
                                <Typography
                                  variant="small"
                                  color="black"
                                  className="font-normal"
                                >
                                  {findIdByValue(name) || "Default Value"}
                                </Typography>
                              </td>
                              <td
                                className={
                                  job === "진행중" ? "p-4 animate-pulse" : "p-4"
                                }
                              >
                                <Chip
                                  variant="ghost"
                                  size="sm"
                                  color={
                                    job === "진행중"
                                      ? "deep-purple"
                                      : job === "완료"
                                      ? "green"
                                      : "red"
                                  }
                                  value={job || "Default Value"}
                                />
                              </td>
                              <td className="p-4">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {date || "Default Value"}
                                </Typography>
                              </td>
                              <td className="p-4">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {credit || "Default Value"}
                                </Typography>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Card>
                </TabPanel>
                <TabPanel
                  key="Profile"
                  value="profile"
                  className="flex flex-col gap-4"
                >
                  <div className="flex justify-between items-center">
                    <Typography
                      color="black"
                      className="w-1/4 font-normal text-sm sm:text-md"
                    >
                      이름
                    </Typography>
                    <Input
                      label={userStore.nickname}
                      disabled
                      size="lg"
                      color="black"
                      crossOrigin={undefined}
                      className="w-3/4"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <Typography
                      color="black"
                      className="w-1/4 font-normal text-sm sm:text-md"
                    >
                      이메일
                    </Typography>
                    <Input
                      label={userStore.email}
                      disabled
                      size="lg"
                      color="black"
                      crossOrigin={undefined}
                      className="w-3/4"
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <Typography
                      color="black"
                      className="w-1/4 font-normal text-sm sm:text-md"
                    >
                      크레딧
                    </Typography>
                    <Input
                      label={String(userStore.credit)}
                      disabled
                      size="lg"
                      color="black"
                      crossOrigin={undefined}
                      className="w-3/4"
                    />
                  </div>
                </TabPanel>
                <TabPanel key="Storage" value="storage">
                  <div className="flex flex-col w-full gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-2 bg-white overflow-auto h-[90vh] w-full">
                      {completedList.length > 0 ? (
                        completedList.map((result, index) => (
                          <div
                            key={index}
                            className="flex flex-col rounded-md bg-white shadow-lg gap-2 p-4 w-full"
                          >
                            <Typography variant="h5" className="font-bold">
                              {findIdByValue(result.versionName) ||
                                "Default Value"}
                            </Typography>

                            <Typography
                              variant="paragraph"
                              className="flex flex-row font-normal gap-1 items-center"
                            >
                              <AlarmClock
                                fill="#ffffff"
                                color="#c30010"
                                strokeWidth={2}
                              />{" "}
                              <span>{result.createdAt}</span>
                            </Typography>

                            {result.outputList.length > 0 && (
                              <div className="items-center justify-center text-center">
                                {spinnerVisible[result.purchaseId!] ? (
                                  <Spinner color="blue" />
                                ) : (
                                  <img
                                    src={
                                      result.outputList[
                                        imageIndexes[result.purchaseId!] ?? 0
                                      ]
                                    }
                                    alt="Output"
                                    className="max-w-full rounded-lg"
                                  />
                                )}
                              </div>
                            )}
                            {result.outputList.length >= 1 && (
                              <div className="flex gap-2 mt-2">
                                <Button
                                  color="gray"
                                  variant="outlined"
                                  size="sm"
                                  onClick={() =>
                                    handlePrevImage(result.purchaseId!)
                                  }
                                  disabled={
                                    imageIndexes[result.purchaseId!] <= 0
                                  }
                                  className="w-full"
                                >
                                  이전
                                </Button>
                                <Button
                                  color="gray"
                                  variant="outlined"
                                  size="sm"
                                  onClick={() =>
                                    handleNextImage(result.purchaseId!)
                                  }
                                  disabled={
                                    imageIndexes[result.purchaseId!] >=
                                    result.outputList.length - 1
                                  }
                                  className="w-full"
                                >
                                  다음
                                </Button>
                                {excuteLoading ? (
                                  <div className="w-1/2">
                                    <Spinner color="blue" className="h-8 w-8" />
                                  </div>
                                ) : (
                                  <Button
                                    color="black"
                                    variant="gradient"
                                    size="sm"
                                    onClick={() => handleDownload(result)}
                                    className="w-1/2"
                                  >
                                    <Download className="w-full text-center" />
                                  </Button>
                                )}
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div className="text-center py-4">
                          완료된 목록이 없습니다.
                        </div>
                      )}
                    </div>

                    <Typography
                      color="red"
                      variant="paragraph"
                      className="font-bold text-red-500"
                    >
                      * 이미지는 일주일동안 보관 됩니다.
                    </Typography>
                  </div>
                </TabPanel>
              </TabsBody>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
