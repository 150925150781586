import { Alert, Chip, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";

export let ToolsImageItem = (type: String) => {
  let data;
  switch (type) {
    case "AvatarStyle":
      return (data = [
        {
          imageLink: "../case/avatar-maker/s1.png",
        },
        {
          imageLink: "../case/avatar-maker/s2.png",
        },
        {
          imageLink: "../case/avatar-maker/s3.png",
        },
        {
          imageLink: "../case/avatar-maker/s4.png",
        },
        {
          imageLink: "../case/avatar-maker/s5.png",
        },
        {
          imageLink: "../case/avatar-maker/s6.png",
        },
      ]);
    case "PortraitStyle":
      return (data = [
        {
          imageLink: "../case/universe-maker/s1.png",
        },
        {
          imageLink: "../case/universe-maker/s2.png",
        },
        {
          imageLink: "../case/universe-maker/s3.png",
        },
        {
          imageLink: "../case/universe-maker/s4.png",
        },
        {
          imageLink: "../case/universe-maker/s5.png",
        },
        {
          imageLink: "../case/universe-maker/s6.png",
        },
      ]);
    case "AnimeStyle":
      return (data = [
        {
          imageLink: "../case/image-to-anime/s1.png",
        },
        {
          imageLink: "../case/image-to-anime/s2.png",
        },
        {
          imageLink: "../case/image-to-anime/s3.png",
        },
        {
          imageLink: "../case/image-to-anime/s4.png",
        },
        {
          imageLink: "../case/image-to-anime/s5.png",
        },
        {
          imageLink: "../case/image-to-anime/s6.png",
        },
      ]);
    case "VariantStyle":
      return (data = [
        {
          imageLink: "../case/variant-avatar-maker/s1.png",
        },
        {
          imageLink: "../case/variant-avatar-maker/s2.png",
        },
        {
          imageLink: "../case/variant-avatar-maker/s3.png",
        },
        {
          imageLink: "../case/variant-avatar-maker/s4.png",
        },
        {
          imageLink: "../case/variant-avatar-maker/s5.png",
        },
        {
          imageLink: "../case/variant-avatar-maker/s6.png",
        },
      ]);
    case "LegoStyle":
      return (data = [
        {
          imageLink: "../case/lego-avatar-maker/s1.png",
        },
        {
          imageLink: "../case/lego-avatar-maker/s2.png",
        },
        {
          imageLink: "../case/lego-avatar-maker/s3.png",
        },
        {
          imageLink: "../case/lego-avatar-maker/s4.png",
        },
        {
          imageLink: "../case/lego-avatar-maker/s5.png",
        },
        {
          imageLink: "../case/lego-avatar-maker/s6.png",
        },
      ]);
    case "MinecraftStyle":
      return (data = [
        {
          imageLink: "../case/minecraft-avatar-maker/s1.png",
        },
        {
          imageLink: "../case/minecraft-avatar-maker/s2.png",
        },
        {
          imageLink: "../case/minecraft-avatar-maker/s3.png",
        },
        {
          imageLink: "../case/minecraft-avatar-maker/s4.png",
        },
        {
          imageLink: "../case/minecraft-avatar-maker/s5.png",
        },
        {
          imageLink: "../case/minecraft-avatar-maker/s6.png",
        },
      ]);
    case "PixelStyle":
      return (data = [
        {
          imageLink: "../case/pixel-avatar-maker/s1.png",
        },
        {
          imageLink: "../case/pixel-avatar-maker/s2.png",
        },
        {
          imageLink: "../case/pixel-avatar-maker/s3.png",
        },
        {
          imageLink: "../case/pixel-avatar-maker/s4.png",
        },
        {
          imageLink: "../case/pixel-avatar-maker/s5.png",
        },
        {
          imageLink: "../case/pixel-avatar-maker/s6.png",
        },
      ]);
    case "FigureStyle":
      return (data = [
        {
          imageLink: "../case/figure-avatar-maker/s1.png",
        },
        {
          imageLink: "../case/figure-avatar-maker/s2.png",
        },
        {
          imageLink: "../case/figure-avatar-maker/s3.png",
        },
        {
          imageLink: "../case/figure-avatar-maker/s4.png",
        },
        {
          imageLink: "../case/figure-avatar-maker/s5.png",
        },
        {
          imageLink: "../case/figure-avatar-maker/s6.png",
        },
      ]);
    // case "appiconMaker":
    //   return (data = [
    //     {
    //       imageLink: "../case/appicon-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/appicon-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/appicon-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/appicon-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/appicon-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/appicon-maker/s1.png",
    //     },
    //   ]);
    // case "mascotMaker":
    //   return (data = [
    //     {
    //       imageLink: "../case/mascot-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/mascot-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/mascot-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/mascot-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/mascot-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/mascot-maker/s1.png",
    //     },
    //   ]);
    // case "emojiMaker":
    //   return (data = [
    //     {
    //       imageLink: "../case/emoji-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/emoji-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/emoji-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/emoji-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/emoji-maker/s1.png",
    //     },
    //     {
    //       imageLink: "../case/emoji-maker/s1.png",
    //     },
    //   ]);
  }
  return data;
};

export const HeaderSectionComponent = (param: any): JSX.Element | null => {
  const [option, setOption] = useState({
    title: "",
    subTitle: "",
    image: "",
    cnt: "",
    credit: "",
    time: "",
    cnt2: "",
    size: "",
  });

  useEffect(() => {
    switch (param.type) {
      case "generalImageMaker":
        return setOption({
          title: "일반 이미지 생성",
          subTitle: "프롬프트로 일반 이미지를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "영어 프롬프트 입력",
          credit: "크레딧 1개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 4장",
          size: "출력 크기 선택",
        });
      case "realisticImageMaker":
        return setOption({
          title: "실사 이미지 생성",
          subTitle: "프롬프트로 실사 이미지를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "영어 프롬프트 입력",
          credit: "크레딧 1개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 4장",
          size: "출력 크기 선택",
        });
      case "animeImageMaker":
        return setOption({
          title: "애니 이미지 생성",
          subTitle: "프롬프트로 애니 이미지를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "영어 프롬프트 입력",
          credit: "크레딧 1개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 4장",
          size: "출력 크기 선택",
        });
      case "appiconMaker":
        return setOption({
          title: "나만의 앱 아이콘",
          subTitle: "프롬프트로 나만의 앱 아이콘을 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "영어 프롬프트 입력",
          credit: "크레딧 1개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 3장",
          size: "출력 크기 1024",
        });
      case "mascotMaker":
        return setOption({
          title: "나만의 마스코트",
          subTitle: "프롬프트로 나만의 마스코트를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "영어 프롬프트 입력",
          credit: "크레딧 1개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 4장",
          size: "출력 크기 1024",
        });
      case "emojiMaker":
        return setOption({
          title: "나만의 이모티콘",
          subTitle: "프롬프트로 나만의 이모티콘을 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "영어 프롬프트 입력",
          credit: "크레딧 1개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 4장",
          size: "출력 크기 1024",
        });

      case "AvatarStyle":
        return setOption({
          title: "나만의 캐릭터 만들기",
          subTitle: "내 얼굴로 나만의 캐릭터를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "입력 이미지 최대 3장",
          credit: "크레딧 10개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 10장",
          size: "출력 크기 1024",
        });
      case "PortraitStyle":
        return setOption({
          title: "나만의 유니버스 프로필",
          subTitle: "내 사진으로 다양하고 멋진 유니버스 프로필을 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "입력 이미지 1장",
          credit: "크레딧 10개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 10장",
          size: "출력 크기 1024",
        });
      case "AnimeStyle":
        return setOption({
          title: "이미지를 일러스트로",
          subTitle: "내 이미지를 멋진 일러스트로 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "입력 이미지 1장",
          credit: "크레딧 1개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 1장",
          size: "출력 크기 1024",
        });
      case "VariantStyle":
        return setOption({
          title: "나만의 다양한 캐릭터",
          subTitle: "내 얼굴로 재미있는 캐릭터를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "입력 이미지 최대 3장",
          credit: "크레딧 10개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 10장",
          size: "출력 크기 1024",
        });
      case "LegoStyle":
        return setOption({
          title: "나만의 레고 만들기",
          subTitle: "내 얼굴로 나만의 레고 캐릭터를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "입력 이미지 최대 3장",
          credit: "크레딧 10개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 10장",
          size: "출력 크기 1024",
        });
      case "MinecraftStyle":
        return setOption({
          title: "나만의 마크 만들기",
          subTitle: "내 얼굴로 나만의 마크 캐릭터를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "입력 이미지 최대 3장",
          credit: "크레딧 10개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 10장",
          size: "출력 크기 1024",
        });
      case "PixelStyle":
        return setOption({
          title: "나만의 픽셀 캐릭터 만들기",
          subTitle: "내 얼굴로 나만의 픽셀 캐릭터를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "입력 이미지 최대 3장",
          credit: "크레딧 10개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 10장",
          size: "출력 크기 1024",
        });
      case "FigureStyle":
        return setOption({
          title: "나만의 피규어 캐릭터",
          subTitle: "내 얼굴로 멋진 피규어 캐릭터를 만들어보세요!",
          image: "이미지 생성 기술",
          cnt: "입력 이미지 최대 3장",
          credit: "크레딧 10개",
          time: "시간 소요 최대 30분",
          cnt2: "출력 장수 10장",
          size: "출력 크기 1024",
        });
    }
  }, [param.type]);

  return (
    <section className="flex flex-col items-center justify-center gap-8">
      <Typography className="p-1 text-2xl sm:text-4xl md:text-5xl font-extrabold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block text-transparent bg-clip-text">
        {option.title}
      </Typography>
      <Typography className="text-sm sm:text-base md:text-lg font-medium">
        {option.subTitle}
      </Typography>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 items-center gap-4">
        <Chip variant="gradient" size="lg" color="amber" value={option.image} />
        <Chip variant="gradient" size="lg" color="lime" value={option.cnt} />
        <Chip
          variant="gradient"
          size="lg"
          color="deep-orange"
          value={option.credit}
        />
        <Chip variant="gradient" size="lg" color="indigo" value={option.time} />
        <Chip variant="gradient" size="lg" color="blue" value={option.cnt2} />
        <Chip variant="gradient" size="lg" color="teal" value={option.size} />
      </div>
    </section>
  );
};

export const CautionComponent = (param: any): JSX.Element | null => {
  const [content, setContent] = useState({
    cationComment1: "",
    cationComment2: "",
    cationComment3: "",
  });

  useEffect(() => {
    switch (param.type) {
      case "AvatarStyle":
        return setContent({
          cationComment1: "⛔️ 셀피 이미지를 입력하세요",
          cationComment2: "⛔️ 고품질 이미지를 입력하세요",
          cationComment3: "⛔️ 입력 이미지 3장을 추천해요",
        });
      case "PortraitStyle":
        return setContent({
          cationComment1: "⛔️ 셀피 이미지를 입력하세요",
          cationComment2: "⛔️ 고품질 이미지를 입력하세요",
          cationComment3: "⛔️ 멋진 이미지를 추천해요",
        });
      case "VariantStyle":
        return setContent({
          cationComment1: "⛔️ 셀피 이미지를 입력하세요",
          cationComment2: "⛔️ 고품질 이미지를 입력하세요",
          cationComment3: "⛔️ 입력 이미지 3장을 추천해요",
        });
      case "LegoStyle":
        return setContent({
          cationComment1: "⛔️ 셀피 이미지를 입력하세요",
          cationComment2: "⛔️ 고품질 이미지를 입력하세요",
          cationComment3: "⛔️ 입력 이미지 3장을 추천해요",
        });
      case "MinecraftStyle":
        return setContent({
          cationComment1: "⛔️ 셀피 이미지를 입력하세요",
          cationComment2: "⛔️ 고품질 이미지를 입력하세요",
          cationComment3: "⛔️ 입력 이미지 3장을 추천해요",
        });
      case "PixelStyle":
        return setContent({
          cationComment1: "⛔️ 셀피 이미지를 입력하세요",
          cationComment2: "⛔️ 고품질 이미지를 입력하세요",
          cationComment3: "⛔️ 입력 이미지 3장을 추천해요",
        });
      case "FigureStyle":
        return setContent({
          cationComment1: "⛔️ 셀피 이미지를 입력하세요",
          cationComment2: "⛔️ 고품질 이미지를 입력하세요",
          cationComment3: "⛔️ 입력 이미지 3장을 추천해요",
        });
      case "AnimeStyle":
        return setContent({
          cationComment1: "⛔️ 인물: 근접한 이미지를 입력",
          cationComment2: "⛔️ 배경: 고품질 이미지를 입력",
          cationComment3: "",
        });
      case "appiconMaker":
        return setContent({
          cationComment1: "⛔️ 영어 단어를 입력",
          cationComment2: "",
          cationComment3: "",
        });
      case "emojiMaker":
        return setContent({
          cationComment1: "⛔️ 영어 단어를 입력",
          cationComment2: "",
          cationComment3: "",
        });
      case "mascotMaker":
        return setContent({
          cationComment1: "⛔️ 영어 단어를 입력",
          cationComment2: "",
          cationComment3: "",
        });
      case "generalImageMaker":
        return setContent({
          cationComment1: "⛔️ 영어 문장을 입력",
          cationComment2: "",
          cationComment3: "",
        });
      case "realisticImageMaker":
        return setContent({
          cationComment1: "⛔️ 영어 문장을 입력",
          cationComment2: "",
          cationComment3: "",
        });
      case "animeImageMaker":
        return setContent({
          cationComment1: "⛔️ 영어 문장을 입력",
          cationComment2: "",
          cationComment3: "",
        });
    }
  }, [param.type]);

  return (
    <section className="flex flex-col items-center justify-center p-5 bg-white rounded-lg shadow-lg">
      <Typography
        variant="h2"
        className="text-3xl sm:text-4xl md:text-4xl font-extrabold bg-gradient-to-r from-red-500 via-red-600 to-red-700 inline-block text-transparent bg-clip-text mb-8"
      >
        주의사항
      </Typography>
      <div className="space-y-3">
        {content.cationComment1 ? (
          <Alert
            color="red"
            variant="outlined"
            className="rounded-md p-1 xs:p-2 sm:p-3 shadow-md font-bold text-sm sm:text-base md:text-2xl"
          >
            {content.cationComment1}
          </Alert>
        ) : null}
        {content.cationComment2 ? (
          <Alert
            color="red"
            variant="outlined"
            className="rounded-md p-1 xs:p-2 sm:p-3 shadow-md font-bold text-sm sm:text-base md:text-2xl"
          >
            {content.cationComment2}
          </Alert>
        ) : null}
        {content.cationComment3 ? (
          <Alert
            color="red"
            variant="outlined"
            className="rounded-md p-1 xs:p-2 sm:p-3 shadow-md font-bold text-sm sm:text-base md:text-2xl"
          >
            {content.cationComment3}
          </Alert>
        ) : null}
      </div>
    </section>
  );
};
