import Footer from "./components/footer";
import { StickyNavbar } from "./components/navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Showcase from "./pages/Showcase";
import Price from "./pages/Price";
import Contact from "./pages/Contact";
import Signin from "./pages/Signin";
import Profile from "./pages/Profile";
import Tools from "./pages/Tools";
import TOS from "./pages/TOS";
import PP from "./pages/PP";
import AnimeStyle from "./pages/toolsDetail/AnimeStyle";
import VariantStyle from "./pages/toolsDetail/VariantStyle";
import AvatarStyle from "./pages/toolsDetail/AvatarStyle";
import PortraitStyle from "./pages/toolsDetail/PortraitStyle";
import FigureStyle from "./pages/toolsDetail/FigureStyle";
import MinecraftStyle from "./pages/toolsDetail/MinecraftStyle";
import LegoStyle from "./pages/toolsDetail/LegoStyle";
import PixelStyle from "./pages/toolsDetail/PixelStyle";
import ScrollToTop from "./components/PageComponent";
import ToolsDetailPage from "./pages/toolsDetail/ToolsDetailPage";

function App() {
  return (
    <div className="App bg-gray-50">
      <div className="mx-auto max-w-6xl ">
        <BrowserRouter>
          <ScrollToTop></ScrollToTop>
          <StickyNavbar />
          <Routes>
            <Route path="*" element={<Home />}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route path="/tools" element={<Tools />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/showcase" element={<Showcase />}></Route>
            <Route path="/price" element={<Price />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/signin" element={<Signin />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
            <Route path="/tos" element={<TOS />}></Route>
            <Route path="/pp" element={<PP />}></Route>
            {/* <Route
              path="/tools/PortraitStyle"
              element={<PortraitStyle />}
            ></Route>
            <Route path="/tools/AvatarStyle" element={<AvatarStyle />}></Route>
            <Route
              path="/tools/VariantStyle"
              element={<VariantStyle />}
            ></Route>
            <Route path="/tools/AnimeStyle" element={<AnimeStyle />}></Route>
            <Route
              path="/tools/MinecraftStyle"
              element={<MinecraftStyle />}
            ></Route>
            <Route path="/tools/FigureStyle" element={<FigureStyle />}></Route>
            <Route path="/tools/LegoStyle" element={<LegoStyle />}></Route>
            <Route path="/tools/PixelStyle" element={<PixelStyle />}></Route> */}
            <Route
              path="/tools/ToolsDetail"
              element={<ToolsDetailPage />}
            ></Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
