import { CardHeader, CardBody, Typography } from "@material-tailwind/react";
import { CircleDollarSign } from "lucide-react";
import { useEffect } from "react";
import { useLocation } from "react-router";

interface HeaderProps {
  src: string;
}

interface CardProps {
  title: string;
  credit: string;
  comment: string;
}

export const ToolsCardHeader = (data: HeaderProps) => {
  return (
    <CardHeader floated={false} color="blue-gray">
      <img src={data.src} alt="ui/ux review check" />
      <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
    </CardHeader>
  );
};

export const ToolsCardBody = (data: CardProps) => {
  return (
    <CardBody>
      <div className="flex items-center justify-between mb-3">
        <Typography
          color="blue-gray"
          className="flex items-center gap-1.5 font-semibold md:font-semibold lg:font-bold text-lg md:text-xl lg:text-2xl"
        >
          {data.title}
        </Typography>
        <Typography
          color="blue-gray"
          className="flex flex-row items-center gap-1 font-normal md:font-medium lg:font-semibold text-sm sm:text-md md:text-lg lg:text-xl"
        >
          {/* <Coins color="#e9ae0b" className="h-5 w-5" /> */}
          <CircleDollarSign
            fill="#ffffff"
            color="#e9ad03"
            strokeWidth={2}
          />{" "}
          {data.credit}
        </Typography>
      </div>
      <Typography
        color="gray"
        className="font-light text-sm md:text-base md:font-normal"
      >
        {data.comment}
      </Typography>
    </CardBody>
  );
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
