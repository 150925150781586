import {
  Card,
  Tabs,
  Tab,
  TabsBody,
  TabsHeader,
  TabPanel,
} from "@material-tailwind/react";
import { ToolsCardBody, ToolsCardHeader } from "../components/PageComponent";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../Store";
import { useState } from "react";

type CardInfo = {
  src: string;
  title: string;
  comment: string;
  credit: string;
  type: string;
  url: string;
  apiUrl: string;
  inputImageCnt: number;
  key: string;
  searchOption: object;
  fileCheck: boolean;
};

const cardData: CardInfo[] = [
  {
    src: "Tools_GeneralImage.png",
    title: "일반 이미지 생성",
    comment: "프롬프트로 일반 이미지를 만들어 보세요.",
    credit: "1",
    type: "generalImageMaker",
    url: "/tools/generalImageMaker",
    apiUrl: "/api/maker/generalImageMaker",
    inputImageCnt: 0,
    key: "prompt",
    searchOption: {
      prompt: true,
      negativePrompt: true,
      aspectRatiosSelection: true,
    },
    fileCheck: false,
  },
  {
    src: "Tools_RealisticImage.png",
    title: "실사 이미지 만들기",
    comment: "프롬프트로 실사 이미지를 만들어 보세요.",
    credit: "1",
    type: "realisticImageMaker",
    url: "/tools/realisticImageMaker",
    apiUrl: "/api/maker/realisticImageMaker",
    inputImageCnt: 0,
    key: "prompt",
    searchOption: {
      prompt: true,
      negativePrompt: true,
      aspectRatiosSelection: true,
    },
    fileCheck: false,
  },
  {
    src: "Tools_AnimeImage.png",
    title: "애니 이미지 만들기",
    comment: "프롬프트로 애니 이미지를 만들어 보세요.",
    credit: "1",
    type: "animeImageMaker",
    url: "/tools/animeImageMaker",
    apiUrl: "/api/maker/animeImageMaker",
    inputImageCnt: 0,
    key: "prompt",
    searchOption: {
      prompt: true,
      negativePrompt: true,
      aspectRatiosSelection: true,
    },
    fileCheck: false,
  },
  {
    src: "Tools_Appicon.png",
    title: "앱 아이콘 만들기",
    comment: "프롬프트로 나만의 앱 아이콘을 만들어 보세요.",
    credit: "1",
    type: "appiconMaker",
    url: "/tools/appiconMaker",
    apiUrl: "/api/maker/appiconMaker",
    inputImageCnt: 0,
    key: "prompt",
    searchOption: {
      sex: false,
      identity: false,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: true,
    },
    fileCheck: false,
  },
  {
    src: "Tools_Mascot.png",
    title: "마스코트 만들기",
    comment: "프롬프트로 나만의 마스코트를 만들어 보세요.",
    credit: "1",
    type: "mascotMaker",
    url: "/tools/mascotMaker",
    apiUrl: "/api/maker/mascotMaker",
    inputImageCnt: 0,
    key: "prompt",
    searchOption: {
      sex: false,
      identity: false,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: true,
    },
    fileCheck: false,
  },
  {
    src: "Tools_Emoji.png",
    title: "이모티콘 만들기",
    comment: "프롬프트로 나만의 이모티콘을 만들어 보세요.",
    credit: "1",
    type: "emojiMaker",
    url: "/tools/emojiMaker",
    apiUrl: "/api/maker/emojiMaker",
    inputImageCnt: 0,
    key: "prompt",
    searchOption: {
      sex: false,
      identity: false,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: true,
    },
    fileCheck: false,
  },
  {
    src: "Tools_Avatar.png",
    title: "일러스트 캐릭터",
    comment: "셀카 몇 장으로 나만의 일러스트 캐릭터를 만들어보세요.",
    credit: "10",
    type: "AvatarStyle",
    url: "/tools/AvatarStyle",
    apiUrl: "/api/maker/avatarMaker",
    inputImageCnt: 3,
    key: "avatar",
    searchOption: {
      sex: true,
      identity: true,
      fashion: true,
      colorTone: true,
      style: false,
      prompt: false,
    },
    fileCheck: true,
  },
  {
    src: "Tools_Universe.png",
    title: "유니버스 프로필",
    comment: "셀카 한 장으로 나만의 유니버스 프로필을 만들어보세요.",
    credit: "10",
    type: "PortraitStyle",
    url: "/tools/PortraitStyle",
    apiUrl: "/api/maker/universeMaker",
    inputImageCnt: 1,
    key: "universe",
    searchOption: {
      sex: true,
      identity: false,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: false,
    },
    fileCheck: true,
  },
  {
    src: "Tools_Anime.png",
    title: "애니메이션 필터",
    comment: "나의 이미지를 고품질 애니메이션 스타일로 바꾸어보세요.",
    credit: "1",
    type: "AnimeStyle",
    url: "/tools/AnimeStyle",
    apiUrl: "/api/maker/imageToAnime",
    inputImageCnt: 1,
    key: "filter",
    searchOption: {
      sex: false,
      identity: false,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: false,
    },
    fileCheck: true,
  },
  // {
  //   src: "Tools_Variant.png",
  //   title: "다양한 캐릭터",
  //   comment: "셀카 몇 장으로 나만의 재미있는 캐릭터를 만들어보세요.",
  //   credit: "10",
  //   type: "VariantStyle",
  //   url: "/tools/VariantStyle",
  //   apiUrl: "/api/maker/variantAvatarMaker",
  //   inputImageCnt: 3,
  //   searchOption: {
  //     sex: true,
  //     age: false,
  //     fashion: false,
  //     color: false,
  //     style: false,
  //   },
  // },
  {
    src: "Tools_Lego.png",
    title: "레고 캐릭터",
    comment: "셀카 몇 장으로 나만의 레고 캐릭터를 만들어보세요.",
    credit: "10",
    type: "LegoStyle",
    url: "/tools/LegoStyle",
    apiUrl: "/api/maker/legoAvatarMaker",
    inputImageCnt: 3,
    key: "avatar",
    searchOption: {
      sex: true,
      identity: true,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: false,
    },
    fileCheck: true,
  },
  {
    src: "Tools_Minecraft.png",
    title: "마크 캐릭터",
    comment: "셀카 몇 장으로 나만의 마인크래프트 캐릭터를 만들어보세요.",
    credit: "10",
    type: "MinecraftStyle",
    url: "/tools/MinecraftStyle",
    apiUrl: "/api/maker/minecraftAvatarMaker",
    inputImageCnt: 3,
    key: "avatar",
    searchOption: {
      sex: true,
      identity: true,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: false,
    },
    fileCheck: true,
  },
  // {
  //   src: "Tools_Pixel.png",
  //   title: "픽셀 캐릭터",
  //   comment: "셀카 몇 장으로 나만의 픽셀 캐릭터를 만들어보세요.",
  //   credit: "10",
  //   type: "PixelStyle",
  //   url: "/tools/PixelStyle",
  //   apiUrl: "/api/maker/pixelAvatarMaker",
  //   inputImageCnt: 3,
  //   searchOption: {
  //     sex: true,
  //     age: false,
  //     fashion: false,
  //     color: false,
  //     style: false,
  //   },
  // },
  {
    src: "Tools_Figure.png",
    title: "피규어 캐릭터",
    comment: "셀카 몇 장으로 나만의 피규어 캐릭터를 만들어보세요.",
    credit: "10",
    type: "FigureStyle",
    url: "/tools/FigureStyle",
    apiUrl: "/api/maker/figureAvatarMaker",
    inputImageCnt: 3,
    key: "avatar",
    searchOption: {
      sex: true,
      identity: true,
      fashion: false,
      colorTone: false,
      style: false,
      prompt: false,
    },
    fileCheck: true,
  },
];

const Tools = () => {
  const navigate = useNavigate();
  const userStore = useUserStore((state) => state);

  const initialTab = "all";
  const [currentTab, setCurrentTab] = useState(initialTab);

  const [currentData, setCurrentData] = useState<CardInfo[]>(cardData);

  const handleTabClick = (tab: string) => {
    setCurrentTab(tab);
    if (tab === "all") {
      return setCurrentData(cardData);
    }
    return setCurrentData(cardData.filter((card) => card.key === tab));
  };

  const ListOnClick = (data: CardInfo) => {
    if (!userStore.nickname) {
      return alert("로그인을 해주세요.");
    }
    navigate("/tools/ToolsDetail", { state: data });
  };

  return (
    <div>
      <Tabs value={currentTab} className="max-w-full gap-4 m-4 lg:gap-8 lg:m-8">
        <TabsHeader
          className="bg-transparent"
          indicatorProps={{
            className: "bg-gray-900/10 shadow-none !text-gray-900",
          }}
        >
          <Tab key="all" value="all" onClick={() => handleTabClick("all")}>
            전체
          </Tab>
          <Tab
            key="prompt"
            value="prompt"
            onClick={() => handleTabClick("prompt")}
          >
            프롬프트
          </Tab>
          <Tab
            key="avatar"
            value="avatar"
            onClick={() => handleTabClick("avatar")}
          >
            아바타
          </Tab>
          <Tab
            key="universe"
            value="universe"
            onClick={() => handleTabClick("universe")}
          >
            유니버스
          </Tab>
          <Tab
            key="filter"
            value="filter"
            onClick={() => handleTabClick("filter")}
          >
            필터
          </Tab>
        </TabsHeader>
        <TabsBody>
          <TabPanel key={currentTab} value={currentTab}>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 m-4 lg:gap-8 lg:m-8">
              {currentData.map((card, index) => (
                <Card
                  key={index}
                  className="shadow-lg cursor-pointer"
                  onClick={() => ListOnClick(card)}
                >
                  <ToolsCardHeader src={card.src}></ToolsCardHeader>
                  <ToolsCardBody
                    title={card.title}
                    comment={card.comment}
                    credit={card.credit}
                  ></ToolsCardBody>
                </Card>
              ))}
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default Tools;
