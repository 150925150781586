import { Link } from "react-router-dom";
import {
  Card,
  Button,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { DatabaseBackup, Eye, Gift } from "lucide-react";
import { useEffect } from "react";
import { useUserStore } from "../Store";
import { config } from "../config";

const Home = () => {
  const userStore = useUserStore((state) => state);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    if (code) {
      let callURL;
      if (queryParams.get("scope")?.includes("google")) {
        callURL = `/google?code=${code}`;
      } else {
        callURL = `/kakao?code=${code}`;
      }
      fetch(`${config.url}/api/auth${callURL}`, {
        method: "POST",
      })
        .then((response) => response.json())
        .then((result) => {
          userStore.setInfo(result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  const data = [
    {
      imageLink: "home/s4.png",
    },
    {
      imageLink: "home/s5.png",
    },
    {
      imageLink: "home/s6.png",
    },
    {
      imageLink: "home/s7.png",
    },
    {
      imageLink: "home/s8.png",
    },
    {
      imageLink: "home/s9.png",
    },
    {
      imageLink: "home/s16.png",
    },
    {
      imageLink: "home/s17.png",
    },
    {
      imageLink: "home/s18.png",
    },
    {
      imageLink: "home/s10.png",
    },
    {
      imageLink: "home/s11.png",
    },
    {
      imageLink: "home/s12.png",
    },
    {
      imageLink: "home/s13.png",
    },
    {
      imageLink: "home/s14.png",
    },
    {
      imageLink: "home/s15.png",
    },
    {
      imageLink: "home/s19.png",
    },
    {
      imageLink: "home/s20.png",
    },
    {
      imageLink: "home/s21.png",
    },
    {
      imageLink: "home/s1.png",
    },
    {
      imageLink: "home/s2.png",
    },
    {
      imageLink: "home/s3.png",
    },
  ];

  return (
    <>
      <div className="grid gap-32">
        <section className="flex flex-col items-center justify-center h-screen space-y-16">
          <div className="rounded-lg flex flex-col justify-center items-center gap-10">
            <Typography className="flex font-medium text-base md:text-xl lg:text-2xl p-2 rounded-lg bg-deep-purple-50">
              &nbsp; 여러분의 이야기를 시작하세요 &nbsp;
            </Typography>
            <Typography className="font-black text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl p-2 bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 bg-clip-text text-transparent inline-block">
              나만의 이미지 만들기
            </Typography>
            <Typography className="flex font-medium text-xs xs:text-sm md:text-xl lg:text-2xl p-2 rounded-lg">
              이미지 생성의 모든 것을 경험하세요.
            </Typography>
          </div>
          <div className="flex justify-center space-x-4">
            <Link to="/tools">
              <Button variant="gradient" color="deep-purple">
                시작하기
              </Button>
            </Link>
            <Link
              to="https://open.kakao.com/o/gwXtyBXf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="outlined" color="deep-purple">
                커뮤니티
              </Button>
            </Link>
          </div>
        </section>

        <section className="flex flex-col items-center justify-center gap-4 m-4 lg:gap-8 lg:m-8 h-screen">
          <Typography className="text-sm sm:text-xl md:text-2xl font-medium max-w-4xl mx-auto px-4 leading-relaxed">
            단{" "}
            <span className="font-bold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 bg-clip-text text-transparent inline-block">
              한장의 얼굴
            </span>{" "}
            로
          </Typography>
          <Typography className="text-sm sm:text-xl md:text-2xl font-medium max-w-4xl mx-auto px-4 leading-relaxed">
            <span className="font-bold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 bg-clip-text text-transparent inline-block">
              수천 가지 이야기
            </span>{" "}
            를 담아보아요.
          </Typography>

          <Typography className="text-sm sm:text-xl md:text-2xl font-medium max-w-4xl mx-auto px-4 leading-relaxed">
            나를 표현하는{" "}
            <span className="font-bold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 bg-clip-text text-transparent inline-block">
              프로필 서비스
            </span>{" "}
            에요.
          </Typography>

          <Typography className="text-sm  sm:text-xl md:text-2xl font-medium max-w-4xl mx-auto px-4 leading-relaxed">
            자신만의{" "}
            <span className="font-bold bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 bg-clip-text text-transparent inline-block">
              아이덴티티
            </span>{" "}
            를 표현하세요.
          </Typography>
        </section>

        <section className="flex flex-col items-center justify-center gap-4 m-4 lg:gap-8 lg:m-8">
          <Typography className="font-extrabold text-2xl sm:text-4xl md:text-5xl p-2 bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block text-transparent bg-clip-text mb-16">
            전문가 팀이 만들어요
          </Typography>
          <div className="grid gap-4 gap-y-16 sm:grid-cols-2 md:grid-cols-3">
            <Card>
              <CardHeader className="flex items-center justify-center bg-gray-50">
                <Eye strokeWidth="1.5" className="w-2/3 h-2/3 text-blue-400" />
              </CardHeader>
              <CardBody>
                <Typography
                  color="blue-gray"
                  className="mb-4 text-lg md:text-xl font-semibold"
                >
                  성능을 검증해요.
                </Typography>
                <Typography color="blue-gray" className="text-sm md:text-base">
                  저품질 이미지 생성 및 변환에 지치신 여러분을 위해 고품질
                  모델만 취급해요.
                </Typography>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="flex items-center justify-center bg-gray-50">
                <Gift
                  strokeWidth="1.5"
                  className="w-2/3 h-2/3 text-deep-purple-400"
                />
              </CardHeader>
              <CardBody>
                <Typography
                  color="blue-gray"
                  className="mb-4 text-lg md:text-xl font-semibold"
                >
                  스타일을 문의해요.
                </Typography>
                <Typography color="blue-gray" className="text-sm md:text-base">
                  원하는 스타일을 문의하시면 연구원이 직접 제작 및 검증 후
                  스토어에 등록해요.
                </Typography>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="flex items-center justify-center bg-gray-50">
                <DatabaseBackup
                  strokeWidth="1.5"
                  className="w-2/3 h-2/3 text-red-400"
                />
              </CardHeader>
              <CardBody>
                <Typography
                  color="blue-gray"
                  className="mb-4 text-lg md:text-xl font-semibold"
                >
                  유지비만 받아요.
                </Typography>
                <Typography color="blue-gray" className="text-sm md:text-base">
                  현재는 여러분들에게 제공할 기술을 위해 최소한의 GPU 서버
                  비용만 받아요.
                </Typography>
              </CardBody>
            </Card>
          </div>
        </section>

        <section className="flex flex-col items-center justify-center gap-4 m-4 lg:gap-8 lg:m-8">
          <Typography
            variant="h1"
            className="font-extrabold text-2xl sm:text-4xl md:text-5xl p-2 bg-gradient-to-r from-blue-600 via-purple-500 to-red-400 inline-block text-transparent bg-clip-text mb-16"
          >
            많은 사람들이 이용해요
          </Typography>
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-3">
            {data.map(({ imageLink }, index) => (
              <Card
                key={index}
                className="w-full cursor-pointer overflow-hidden transition-opacity hover:opacity-90"
              >
                <img
                  className="h-full w-full object-cover object-center"
                  src={imageLink}
                  alt="gallery-0"
                />
              </Card>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
